@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&display=swap');

* {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  background-color: rgb(238, 231, 225);
  color: rgb(49, 48, 48);
  text-decoration: none;
}

h1 {
  color: #3b75eb;
}
